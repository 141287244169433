import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/PostLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Epistemic = makeShortcode("Epistemic");
const TableOfContents = makeShortcode("TableOfContents");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Epistemic effort mdxType="Epistemic">
  This is a note for future me. A few code snippets grouped together.
    </Epistemic>
    <p>{`I spent too long looking at React Profiler and tweaking rendering
performance today. Here are a few tricks I learned.`}</p>
    <TableOfContents mdxType="TableOfContents" />
    <p><strong parentName="p">{`Huge disclaimer.`}</strong>{` If you’re new to React or programming in general, you
don’t need to worry about performance. Read `}<a parentName="p" {...{
        "href": "https://kentcdodds.com/blog/usememo-and-usecallback"
      }}>{`“When to useMemo and
useCallback”`}</a>{` on Kent C. Dodds’s blog.`}</p>
    <p>{`I have already worked for a year on the app I was optimizing today. A year
of`}{` `}{`my fulltime job! And I wasn’t the only developer. It has both
keyframes and react‑spring animations, an API client codegenned from
Protobuf schema, and obeying Zawinski’s law, it also reads and sends email.`}</p>
    <p>{`It isn’t too soon to think about performance anymore, because it affects
user experience on weaker machines. I just had to make this point.
Let’s`}{` `}{`go`}{` `}{`to the main part.`}</p>
    <h2 {...{
      "id": "finding-the-culprit-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#finding-the-culprit-",
        "aria-label": "finding the culprit  permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Finding the culprit 🔎`}</h2>
    <p>{`We throttled our CPU and found the part of the app which feels wrong. But
how do we find the slow components? How can we know what to optimize?`}</p>
    <p>{`The official React Profiler will answer these questions.`}</p>
    <figure>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "891px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "17.681159420289855%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAAsSAAALEgHS3X78AAAAi0lEQVQI102ObQvDIAyE+/9/5Tb2oasvpxWsJDVZtBT6EPDEO3OLcy7n7L2PBjAEECYAUgJisElDIV4AnzV8V7+o6il6UG/EXbT3c9ubfWfX4U75Fer7l5h5OCcmRFRUR1juuch53yZWyrZsAS6Ota21PrmNM/xERGqtVs36W4CITiZmO6mUYk/P8B/SoeZfFuUcEQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "why did you render  react profiler",
            "title": "why did you render  react profiler",
            "src": "/static/50f93961799a2bb261826db7f6e1c8c0/b7877/why-did-you-render__react-profiler.png",
            "srcSet": ["/static/50f93961799a2bb261826db7f6e1c8c0/e4d6b/why-did-you-render__react-profiler.png 345w", "/static/50f93961799a2bb261826db7f6e1c8c0/1e043/why-did-you-render__react-profiler.png 690w", "/static/50f93961799a2bb261826db7f6e1c8c0/b7877/why-did-you-render__react-profiler.png 891w"],
            "sizes": "(max-width: 891px) 100vw, 891px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></p>
      <figcaption>
        <p>{`Today! In your
`}<a parentName="p" {...{
            "href": "https://chrome.google.com/webstore/detail/react-developer-tools/fmkadmapgofadopljbjfkapdkoienihi"
          }}>{`DevTools`}</a>{`!
Don’t forget to mark this checkbox.`}</p>
      </figcaption>
    </figure>
    <figure>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "295px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.6271186440678%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsSAAALEgHS3X78AAABHUlEQVQY042PzUrDQBSF8xBu9CHsVgkR0Y24EXw2kRY3IqhFLQTfIRDTlJI0k6RtJj9tjVKkJunMNDjTjGlRUPCnh4/DuYvLvUdQFCUIAl3XTdP0PA9CqGlamQkhnPOiKPjvElRVNZcyDMOyLACA67pRFFFK8zzHSzHGfl6eJGkQDUofPsbdvgdsB5HZ+GWSIRI/j8PhKIiGZWYFf2MFnfOvCJqqtJoP7VbT7hihD/999dvlzZNArPlbtVA8G+yex8cyPmxg6RpLdbLwD8hi/GSnTravyJE8EyqX7OCe78mFdDeXbul+g4o3dL2K/mCjitZOUeUCC+Hoqd1xQA/aPej0fd0AXRiWDVdBIBilyes0SwiaoizNypwmK3Z+B7mwmpOKMOUSAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "why did you render  why did this render",
            "title": "why did you render  why did this render",
            "src": "/static/cb3ecf05464e930982e531dd73033ddf/e4a3f/why-did-you-render__why-did-this-render.png",
            "srcSet": ["/static/cb3ecf05464e930982e531dd73033ddf/e4a3f/why-did-you-render__why-did-this-render.png 295w"],
            "sizes": "(max-width: 295px) 100vw, 295px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></p>
      <figcaption>
        <p>{`We can see which props changed.`}</p>
      </figcaption>
    </figure>
    <p>{`It may be, however, laconic in its answers, when the hooks cause the update.
The issue requesting this is open since June 2019.`}</p>
    <small>
      <p><a parentName="p" {...{
          "href": "https://github.com/facebook/react/issues/16477"
        }}><em parentName="a">{`DevTools: Profiler: Show which hooks changed #16477`}</em></a></p>
    </small>
    <h2 {...{
      "id": "the-tools-of-deduction-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-tools-of-deduction-",
        "aria-label": "the tools of deduction  permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`The Tools of Deduction 🔬`}</h2>
    <p>{`So we know which component is causing trouble, but we need more details to
discover the cause. Wrapping everything in useMemo is a dumb idea. We call
ourselves engineers; let’s pretend to be serious.`}</p>
    <p>{`We download the results of our profiling, call them `}<em parentName="p">{`“before.json”`}</em>{` or
something like that, and snap a few screenshots for convenience. We’ll need
them for comparison later, to make sure our changes are truly improvements.`}</p>
    <h3 {...{
      "id": "monkey-patching-react-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#monkey-patching-react-",
        "aria-label": "monkey patching react  permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Monkey patching React 🐒`}</h3>
    <p><inlineCode parentName="p">{`@welldone-software/why-did-you-render`}</inlineCode>{` monkey patches React to detect
avoidable re-renders. Tools for debugging are the only good use case for
monkey patching.`}</p>
    <p>{`I added this `}<em parentName="p">{`beautiful`}</em>{` piece of code to my entry point.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (process`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`env`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`NODE_ENV`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`===`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`development`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  document`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`title`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`+=`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{` 🌴`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`DEBUG_PERF`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`false`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`DEBUG_PERF`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`// @ts-ignore for CI; why-did-you-render is in dev deps`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` WhyDidYouRender `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`typeof`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`@welldone-software/why-did-you-render`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`).`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`default`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`// eslint-disable-next-line global-require, import/no-extraneous-dependencies`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`whyDidYouRender`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`require`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`@welldone-software/why-did-you-render`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` as `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`WhyDidYouRender`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`whyDidYouRender`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(React, {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      trackAllPureComponents: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`true`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      collapseGroups: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`true`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      trackExtraHooks: [`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        [`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`          `}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`// eslint-disable-next-line global-require`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`          `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`require`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`./data/store`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`__internal_import_me_only_for_debugging__store`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`          `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`useSelectors`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        ],`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      ],`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    });`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Honestly, it didn’t help me that much because I already profiled the app and
had a fairly good idea what’s wrong. I just didn’t know the exact reason.`}</p>
    <h3 {...{
      "id": "usewhydidyouupdate-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#usewhydidyouupdate-",
        "aria-label": "usewhydidyouupdate  permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`useWhyDidYouUpdate 🎣`}</h3>
    <p>{`Based on a recipe I found at
`}<a parentName="p" {...{
        "href": "https://usehooks.com/useWhyDidYouUpdate"
      }}>{`usehooks.com`}</a>{`, I’ve added a new
hook to my codebase. Sprinkled it with some TypeScript, changed
`}<inlineCode parentName="p">{`console.log`}</inlineCode>{` for `}<inlineCode parentName="p">{`console.group`}</inlineCode>{` and `}<inlineCode parentName="p">{`console.table`}</inlineCode>{`, et voilà!`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`/* eslint-disable no-console */`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { useRef, useEffect } `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`react`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`/**`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{` * Based on https://usehooks.com/useWhyDidYouUpdate/`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{` *`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{` * `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`@param`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk42"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{` a key to identify console group`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{` * `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`@param`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk42"
          }}>{`props`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{` object to persist and compare`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{` */`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`function`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`useWhyDidYouUpdate`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk44"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`string`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk44"
          }}>{`props`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Record`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`string`}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`,`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`unknown`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk36"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`previousProps`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`useRef`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Record`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`string`}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`,`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`unknown`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`>>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`();`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`useEffect`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`()`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const { `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`current`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` } = `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`previousProps;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (current) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`allKeys`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`Object`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`keys`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`{ `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`...`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`current`}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`,`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`...`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`props`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` }`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`);`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`changesObj`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`Record`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`string`}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`,`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`unknown`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`> = {}`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      allKeys`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`forEach`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk44"
          }}>{`key`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (current[key] `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`!==`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` props[key]) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`          changesObj[key] `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            from: current[key]`}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            to: props[key]`}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`          };`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      });`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`console`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`group`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`%c[why-did-you-update] %c`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`\${`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`name`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`font-weight: bold;`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk17"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`color: dodgerblue; font-weight: bold;`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      );`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`Object`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`keys`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(changesObj)`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`length`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`console`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`table`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(changesObj);`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      } `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`else`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`console`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`log`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`Something else changed.`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`);`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`console`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`groupEnd`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`();`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    previousProps`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`current`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` props;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  });`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`I added it to `}<a parentName="p" {...{
        "href": "https://eslint.org/docs/rules/no-restricted-syntax"
      }}><inlineCode parentName="a">{`no-restricted-syntax`}</inlineCode></a>{` ESLint rule, to make sure
I don’t deploy it staging by accident.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "678px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "42.608695652173914%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsSAAALEgHS3X78AAABV0lEQVQoz11R227DIAzN/3/ZHia1ndo0gYSLCfdASPbQVunDnHbVLgdjHVsc20BlnQshurhoP9tQrC/aFW281gbzBpkPWtuHN2Yw45jHlDEsZa4oYQo06URLpRp8oyzYOOKKMacUNkTc45hCRBJRc71eL5fL7Xarhs5Z7Qjtu44JARSdhJynPJWUp6mUh9swzzP6ZVnuD6zrip07oXQNoVXxDL4F34A/CffBXC19Ld1RupjyXL6B4vWFitJeKd0zaV1gXEoYeia4ACEVf4Qp5fILf8SE9ACK9dxax5kUXPLNAKdneAUu8fT8wn8xyvSgUYZdjDEYIsFyUm4VpYBnHp/XWee9x1I/YgGD0gaUxjkH48KY8CfGnGOeNpIyZuKWmZ68LJ+39Y52Xe/V29HtmrBrw/vZ7xpLyEAbQc+CNrIjihJFmG953Nfi0KhTZw9nqFnY1bCv4QstUumay4nC/AAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "why did you render  screenshot 2",
          "title": "why did you render  screenshot 2",
          "src": "/static/e2d0fd1623ff010342cafaba62693afa/38cea/why-did-you-render__screenshot-2.png",
          "srcSet": ["/static/e2d0fd1623ff010342cafaba62693afa/e4d6b/why-did-you-render__screenshot-2.png 345w", "/static/e2d0fd1623ff010342cafaba62693afa/38cea/why-did-you-render__screenshot-2.png 678w"],
          "sizes": "(max-width: 678px) 100vw, 678px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`And here’s how it looks like in browser devtools.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1011px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "40.57971014492753%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsSAAALEgHS3X78AAABBUlEQVQY021R2W7EIAzM/3/kSl1pS5YQDhuwzVHVpC9ZqaOROWSYGdgSgD0O7/2JdQexKAdKqdSa3MnMItx7izE+Ho/n13M33xsxG+u4jdZ6a6uTSBKWyu1OwFpIrnnXZiVJ3wDw7c6YwJdmyww025h9jL7qnWtnzJ9cqjH72x6nD1up1R6uFHVKhVhJxCGA1jtAvVQSkZKzZowhpJQ2RHDuzDkn6sDjR28fK8L4RF8Yc06V2ffdWhuCKpeiLvR8wrxkWZVJ7+JPaNufF53om6ksAKhyVttrVcXXoQw05D9lraqMiK/XyxjjnFvKp/dXUq7XoNlCxHtm/aiY8sqsPbXmC3rwFzylzA5+Kp2CAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "why did you render  screenshot 1",
          "title": "why did you render  screenshot 1",
          "src": "/static/9bc88e1c8cfda558a8434a82adf63da6/6bfd0/why-did-you-render__screenshot-1.png",
          "srcSet": ["/static/9bc88e1c8cfda558a8434a82adf63da6/e4d6b/why-did-you-render__screenshot-1.png 345w", "/static/9bc88e1c8cfda558a8434a82adf63da6/1e043/why-did-you-render__screenshot-1.png 690w", "/static/9bc88e1c8cfda558a8434a82adf63da6/6bfd0/why-did-you-render__screenshot-1.png 1011w"],
          "sizes": "(max-width: 1011px) 100vw, 1011px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`That’s all! How do you investigate performance concerns in your apps?
Did`}{` `}{`I miss something? Feel free to reach out and let me know!`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .night-owl-no-italics {
background-color: #011627;
color: #d6deeb;
}

.night-owl-no-italics .mtk1 { color: #D6DEEB; }
.night-owl-no-italics .mtk2 { color: #011627; }
.night-owl-no-italics .mtk3 { color: #C792EA; }
.night-owl-no-italics .mtk4 { color: #82B1FF; }
.night-owl-no-italics .mtk5 { color: #ADDB67; }
.night-owl-no-italics .mtk6 { color: #637777; }
.night-owl-no-italics .mtk7 { color: #82AAFF; }
.night-owl-no-italics .mtk8 { color: #F78C6C; }
.night-owl-no-italics .mtk9 { color: #7FDBCA; }
.night-owl-no-italics .mtk10 { color: #FF5874; }
.night-owl-no-italics .mtk11 { color: #FFEB95; }
.night-owl-no-italics .mtk12 { color: #FFCB8B; }
.night-owl-no-italics .mtk13 { color: #7FDBCAFF; }
.night-owl-no-italics .mtk14 { color: #80CBC4; }
.night-owl-no-italics .mtk15 { color: #FF6363; }
.night-owl-no-italics .mtk16 { color: #57EAF1; }
.night-owl-no-italics .mtk17 { color: #5F7E97; }
.night-owl-no-italics .mtk18 { color: #ECC48D; }
.night-owl-no-italics .mtk19 { color: #B2CCD6; }
.night-owl-no-italics .mtk20 { color: #FAD430; }
.night-owl-no-italics .mtk21 { color: #FFFFFF; }
.night-owl-no-italics .mtk22 { color: #FF2C83; }
.night-owl-no-italics .mtk23 { color: #020E14; }
.night-owl-no-italics .mtk24 { color: #D3423E; }
.night-owl-no-italics .mtk25 { color: #EC5F67; }
.night-owl-no-italics .mtk26 { color: #8BD649; }
.night-owl-no-italics .mtk27 { color: #5CA7E4; }
.night-owl-no-italics .mtk28 { color: #A2BFFC; }
.night-owl-no-italics .mtk29 { color: #EF535090; }
.night-owl-no-italics .mtk30 { color: #ADDB67FF; }
.night-owl-no-italics .mtk31 { color: #697098; }
.night-owl-no-italics .mtk32 { color: #FF869A; }
.night-owl-no-italics .mtk33 { color: #DDDDDD; }
.night-owl-no-italics .mtk34 { color: #E0DEC6; }
.night-owl-no-italics .mtk35 { color: #CDEBF7; }
.night-owl-no-italics .mtk36 { color: #D9F5DD; }
.night-owl-no-italics .mtk37 { color: #6AE9F0; }
.night-owl-no-italics .mtk38 { color: #C789D6; }
.night-owl-no-italics .mtk39 { color: #BEC5D4; }
.night-owl-no-italics .mtk40 { color: #31E1EB; }
.night-owl-no-italics .mtk41 { color: #8EACE3; }
.night-owl-no-italics .mtk42 { color: #78CCF0; }
.night-owl-no-italics .mtk43 { color: #FAF39F; }
.night-owl-no-italics .mtk44 { color: #D7DBE0; }
.night-owl-no-italics .mtk45 { color: #7986E7; }
.night-owl-no-italics .mtki { font-style: italic; }
.night-owl-no-italics .mtkb { font-weight: bold; }
.night-owl-no-italics .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      